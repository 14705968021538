import './App.css';

import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
 
import About from './NewComponents/About';



function App() {
const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutComponent />,
    
  },
  {
    path:'/about',
    element:<About/>
  }
]);  

function LayoutComponent() {
  return (
   <div>
        <section className="bg-gray-700 h-screen   bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/500/background.jpg')] bg-cover bg-center bg-no-repeat bg-blend-multiply">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
         
          <p className="pt-40 text-3xl font-bold tracking-tight text-white md:text-4xl">
           Coming Soon !!!
          </p>
          <div className='text-center m-6'>
            <button className='bg-white' onClick={()=> window.open("https://www.facebook.com/theflyingpanda.io", "_blank")}>
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clip-rule="evenodd"/>
</svg>

            </button>
            <button className='bg-white ml-2' onClick={()=> window.open("https://www.instagram.com/theflyingpanda.io/", "_blank")}>
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd"/>
</svg>
            </button>

         
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}

  return (
    <div>
       <BrowserRouter>
      <Routes>
      <Route path="/" element={<LayoutComponent />} />
      <Route path="/about" element={<About />} />
    </Routes>
    </BrowserRouter>
    </div>
  );
}







// function App() {
//   return (
//     <div>
//       <Header/>
//       <section class="bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/coast-house-view.jpg')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply min-h-screen">
//  <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
//         <div class="mr-auto place-self-center lg:col-span-7">
//             <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white dark:text-white">Schengen Visa Appointments Made Simple.</h1>
//             <p class="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-700">Get your Schengen visa appointment without refreshing the visa centre site every minute. Leave the hard part to our bot.</p>
             
//         </div>
                    
//     </div>
// </section>
// <section class="bg-white dark:bg-gray-900">
//   <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
//       <div class="max-w-screen-md mb-8 lg:mb-16">
//           <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for business teams like yours</h2>
//           <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
        
//       </div>
//       <div class="mb-4 lg:mb-8 space-y-8 lg:grid lg:grid-cols-3 md:gap-12 xl:gap-16 lg:space-y-0">
         
//           <div class="flex flex-col max-w-lg text-gray-900 dark:text-gray-400">
//               <h3 class="font-semibold text-gray-500 uppercase dark:text-gray-400">Freelancer</h3>
//               <div class="flex items-baseline my-4">
//                   <span class="mr-2 text-5xl font-extrabold text-gray-900 dark:text-white">$49</span>
//                   <span class="text-gray-500 dark:text-gray-400">/month</span>
//               </div>
//               <p class="font-light text-gray-500 sm:text-lg dark:text-gray-300">Best option for personal use and for your next side projects.</p>
              
//               <ul role="list" class="my-8 space-y-4 text-left">
//                   <li class="flex items-center space-x-3">
                       
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>All tools you need to manage payments</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                     
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>No setup, monthly, or hidden fees</span>
//                   </li>
//               </ul>
              
//           </div>
          
//           <div class="flex flex-col max-w-lg text-gray-900 dark:text-gray-400">
//               <h3 class="font-semibold text-gray-500 uppercase dark:text-gray-400">Company</h3>
//               <div class="flex items-baseline my-4">
//                   <span class="mr-2 text-5xl font-extrabold text-gray-900 dark:text-white">$199</span>
//                   <span class="text-gray-500 dark:text-gray-400">/month</span>
//               </div>
//               <p class="font-light text-gray-500 sm:text-lg dark:text-gray-300">Best option for personal use and for your next side projects.</p>
              
//               <ul role="list" class="my-8 space-y-4 text-left">
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>All tools you need to manage payments</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>No setup, monthly, or hidden fees</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Comprehensive security and rigorous</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Get hundreds of feature updates</span>
//                   </li>
//               </ul>
              
//           </div>
          
//           <div class="flex flex-col max-w-lg text-gray-900 dark:text-gray-400">
//               <h3 class="font-semibold text-gray-500 uppercase dark:text-gray-400">Enterprise</h3>
//               <div class="flex items-baseline my-4">
//                   <span class="mr-2 text-5xl font-extrabold text-gray-900 dark:text-white">$999</span>
//                   <span class="text-gray-500 dark:text-gray-400">/month</span>
//               </div>
//               <p class="font-light text-gray-500 sm:text-lg dark:text-gray-300">Best option for personal use and for your next side projects.</p>
              
//               <ul role="list" class="my-8 space-y-4 text-left">
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>All tools you need to manage payments</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>No setup, monthly, or hidden fees</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Comprehensive security</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Get hundreds of feature updates</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                    
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Payouts to your bank accounts</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Financial reconciliation</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>24×7chat and email support</span>
//                   </li>
//                   <li class="flex items-center space-x-3">
                      
//                       <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
//                       <span>Robust developer platform</span>
//                   </li>
//               </ul>
//           </div>
//       </div>
//   </div>
// </section>
// <Faq/>
// <Contact/>
// <Footer />
//     </div>
//   );
// }

export default App;
